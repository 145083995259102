

























































import LanguageSwitchMixin from '../../../Mixin/LanguageSwitchMixin';
import Icon from '../../Misc/Icon/Icon.vue';
import JobMailTeaser from '../JobMailTeaser/JobMailTeaser.vue';
import JobContractTypeFilter from './Type/JobContractTypeFilter/JobContractTypeFilter.vue';
import JobDateFilter from './Type/JobDateFilter/JobDateFilter.vue';
import JobEmploymentTypeFilter from './Type/JobEmploymentTypeFilter/JobEmploymentTypeFilter.vue';
import JobLocationFilter from './Type/JobLocationFilter/JobLocationFilter.vue';
import JobLocationRadiusFilter from './Type/JobLocationRadiusFilter/JobLocationRadiusFilter.vue';
import JobOccupationFilter from './Type/JobOccupationFilter/JobOccupationFilter.vue';
import JobQueryFilter from './Type/JobQueryFilter/JobQueryFilter.vue';

export default {
    name: 'JobListFilter',
    components: {
        JobMailTeaser,
        JobContractTypeFilter,
        JobOccupationFilter,
        JobDateFilter,
        JobEmploymentTypeFilter,
        JobLocationRadiusFilter,
        JobLocationFilter,
        JobQueryFilter,
        Icon
    },
    mixins: [LanguageSwitchMixin],
    methods: {
        onCloseClick()
        {
            this.$emit('close');
        }
    }
};
