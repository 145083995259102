var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        staticClass:
          "jobSortingSelection sorting jobFilter jobFilter--jobSorting",
      },
      "div",
      _vm.iconHoverContainerProps(),
      false
    ),
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v(_vm._s(_vm.$t("jobFilter.form.sorting.label"))),
      ]),
      _vm._v(" "),
      _c("select-box", {
        attrs: {
          id: "job-search-sorting",
          items: _vm.items,
          "hide-arrow": "",
          "disable-search": true,
        },
        scopedSlots: _vm._u([
          {
            key: "input-end",
            fn: function () {
              return [
                _c(
                  "icon",
                  _vm._b(
                    {
                      staticClass: "selectBox__icon jobSortingSelection__icon",
                      attrs: { icon: "sort" },
                    },
                    "icon",
                    _vm.iconHoverProps(),
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.demand.sorting,
          callback: function ($$v) {
            _vm.$set(_vm.demand, "sorting", $$v)
          },
          expression: "demand.sorting",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }