




































import SelectBox from '../../Form/SelectBox/SelectBox.vue';
import Icon from '../../Misc/Icon/Icon.vue';
import IconHoverMixin from '../../Misc/Icon/IconHoverMixin';
import JobFilterMixin from '../JobFilter/Type/JobFilterMixin';

export default {
  name: 'JobSortingSelection',
  components: {Icon, SelectBox},
  mixins: [JobFilterMixin, IconHoverMixin],
  computed: {
    items()
    {
      const list = [
        {
          label: this.$t('jobFilter.form.sorting.option.relevance'),
          value: 'relevance'
        },
        {
          label: this.$t('jobFilter.form.sorting.option.latest'),
          value: 'latest'
        }
      ];

      if (this.demand.location) {
        list.push({
          label: this.$t('jobFilter.form.sorting.option.distance'),
          value: 'distance'
        });
      } else if (this.demand.sorting === 'distance') {
        this.demand.sorting = 'relevance';
      }

      return list;
    }
  }
};
