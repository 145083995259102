



























import SelectBox from '../../../../Form/SelectBox/SelectBox.vue';
import Icon from '../../../../Misc/Icon/Icon.vue';
import JobFilterMixin from '../JobFilterMixin';

export default {
    name: 'JobLocationRadiusFilter',
    components: {Icon, SelectBox},
    mixins: [JobFilterMixin],
    data()
    {
        return {
            options: [
                {
                    label: this.$t('jobFilter.form.radius.option.unlimited'),
                    value: null
                },
                {
                    label: this.$t('jobFilter.form.radius.option.prefix') + ' 5 km',
                    value: 5
                },
                {
                    label: this.$t('jobFilter.form.radius.option.prefix') + ' 15 km',
                    value: 15
                },
                {
                    label: this.$t('jobFilter.form.radius.option.prefix') + ' 25 km',
                    value: 25
                },
                {
                    label: this.$t('jobFilter.form.radius.option.prefix') + ' 50 km',
                    value: 50
                },
                {
                    label: this.$t('jobFilter.form.radius.option.prefix') + ' 100 km',
                    value: 100
                },
                {
                    label: this.$t('jobFilter.form.radius.option.prefix') + ' 250 km',
                    value: 250
                }
            ]
        };
    }
};
