
























import {filter, isString} from '@labor-digital/helferlein';
import {formatDateAndTime} from '@labor-digital/helferlein/lib/FormatAndConvert/formatDateAndTime';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {isArray} from '@labor-digital/helferlein/lib/Types/isArray';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {JobDemand} from '../../../Interface/JobDemand';
import {JobService} from '../../../Service/JobService';
import Chips from '../../Form/Chips/Chips.vue';

const forbiddenKeys = ['query', 'sorting', 'advancedQuery'];

export default {
    name: 'JobChipFilter',
    components: {Chips},
    computed: {
        demand(): JobDemand
        {
            return JobService.demand;
        },
        chips(): Array<PlainObject>
        {
            let chips = [];
            forEach(this.demand, (value, key) => {
                if (isEmpty(value) || forbiddenKeys.indexOf(key) !== -1) {
                    return;
                }
                if (isArray(value)) {
                    forEach(value, (childValue) => {
                        if (isString(childValue)) {
                            childValue = {value: childValue, label: childValue};
                        }
                        if (childValue.value && childValue.label) {
                            chips.push({
                                label: childValue.label,
                                value: {
                                    key: key,
                                    value: childValue.value
                                }
                            });
                        }
                    });
                } else {
                    let label = value;

                    switch (key) {
                        case 'radius':
                            label += ' km';
                            break;
                        case 'location':
                            label = (value as PlainObject).address;
                            break;
                        case 'start':
                            label = this.$t('jobFilter.form.dates.start.label') + ': ' +
                                    formatDateAndTime('d.m.Y', new Date(label as string));
                            break;
                        case 'end':
                            label = this.$t('jobFilter.form.dates.end.label') + ': ' +
                                    formatDateAndTime('d.m.Y', new Date(label as string));
                            break;
                    }

                    chips.push({
                        key: key,
                        label: label,
                        value: {
                            key: key,
                            value: value
                        }
                    });
                }
            });

            return chips;
        }
    },
    methods: {
        removeChip(item)
        {
            const key = item.value.key;
            const demandValue = this.demand[key];
            if (demandValue) {
                if (isArray(demandValue)) {
                    this.demand[key] = filter(demandValue, (v) => {
                        return !(v.value === item.value.value || v === item.value.value);
                    });
                } else {
                    this.demand[key] = null;
                }
            }
        }
    }
};
