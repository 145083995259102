


















































































import {isEmpty} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {State} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import SelectBox from '../../../../Component/Form/SelectBox/SelectBox.vue';
import JobChipFilter from '../../../../Component/Job/JobChipFilter/JobChipFilter.vue';
import JobListFilter from '../../../../Component/Job/JobFilter/JobListFilter.vue';
import JobItemList from '../../../../Component/Job/JobItemList/JobItemList.vue';
import JobSortingSelection from '../../../../Component/Job/JobSortingSelection/JobSortingSelection.vue';
import GreenContainer from '../../../../Component/Layout/GreenContainer/GreenContainer.vue';
import EmptyListNotification from '../../../../Component/Misc/EmptyListNotification/EmptyListNotification.vue';
import Icon from '../../../../Component/Misc/Icon/Icon.vue';
import CmsTextLinkBlock from '../../../../Component/Misc/Link/CmsTextLinkBlock/CmsTextLinkBlock.vue';
import LinkWrap from '../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import InfinityLoadingSpinner from '../../../../Component/Misc/Spinner/InfinityLoadingSpinner.vue';
import {Job} from '../../../../Interface/Job';
import {JobDemand} from '../../../../Interface/JobDemand';
import {JobService} from '../../../../Service/JobService';

export default {
    name: 'JobSearch',
    components: {
        LinkWrap,
        CmsTextLinkBlock,
        JobListFilter,
        JobItemList,
        GreenContainer,
        InfinityLoadingSpinner,
        JobSortingSelection,
        SelectBox,
        Icon,
        JobChipFilter,
        EmptyListNotification
    },
    props: {
        context: null as ContentElementContext
    },
    metaInfo()
    {
        let head = {};
        if (!isEmpty(this.metaDescription)) {
            head['meta'] = [
                {'name': 'description', 'content': this.metaDescription, 'vmid': 't3-description'}
            ];
        }

        if (this.isSerps) {
            head['title'] = this.context.data.get('title');
        }

        return head;
    },
    data()
    {
        return {
            unbinder: () => {},
            result: [],
            page: this.context.data.get('page'),
            pages: 2,
            noMore: false,
            infiniteId: 1,
            isFilterShown: false,
            isLoaded: false
        };
    },
    computed: {
        isSerps(): boolean
        {
            return this.context.data.get('serps') === true;
        },
        hasSerpText(): boolean
        {
            return this.isSerps && !isEmpty(this.serpText);
        },
        serpText(): string
        {
            return this.context.data.get('text', '') + '';
        },
        metaDescription(): string | null
        {
            const metaDescription = this.context.data.get('metaDescription', null);
            if (!isEmpty(metaDescription)) {
                return metaDescription;
            }

            if (!isEmpty(this.demand?.location?.address)) {
                this.$t('branch.detail.meta.applicants', [this.demand.location.address, this.demand.location.address]);
            }

            return null;
        },
        demand(): JobDemand
        {
            return JobService.demand;
        },
        jobs(): Array<void> | PlainObject<Job>
        {
            return this.result;
        },
        hasFilterInSidebar(): boolean
        {
            return this.context.store.get(AppStoreKeys.BREAKPOINT).is('<=', 'md');
        }
    },
    methods: {
        onPaginationClick(page: number)
        {
            this.onSearchChange(page);
        },
        onSearchChange(page?)
        {
            this.isLoaded = false;

            this.result = [];
            this.page = page ?? 1;

            JobService.getDemandedJobs({
                page: this.page
            }).then(jobs => {
                this.result = this.result.concat(jobs.getRaw());
                this.pages = jobs.pagination.pages;
                this.page = jobs.pagination.page;
                this.isLoaded = true;
            });
            history.pushState(null, null, this.paginationLink(this.page));
            document.getElementById('jobContainer').scrollIntoView();
        },
        paginationLink(page: number): string
        {
            const context: ContentElementContext = this.context;
            if (page === 1) {
                return context.pageContext.linkRepository.get('jobList');
            }
            return context.pageContext.linkRepository.get('jobList') + '/' + page;
        },
        paginationClasses(page: number): PlainObject
        {
            return {
                'jobSearch__pagination--active': page === this.page,
                'jobSearch__pagination--before': this.page - 2 === page && this.page - 3 !== 1,
                'jobSearch__pagination--after': this.page + 2 === page && this.page + 3 !== this.pages,
                'jobSearch__pagination--hidden': this.page + 3 <= page || this.page - 3 >= page,
                'jobSearch__pagination--first': page === 1,
                'jobSearch__pagination--last': page === this.pages
            };
        },
        onMounted()
        {
            this.isLoaded = false;
            JobService.getDemandedJobs({
                page: this.page
            }).then(jobs => {
                this.pages = jobs.pagination.pages;

                if (this.page > this.pages) {
                    const context: ContentElementContext = this.context;
                    const link = context.pageContext.linkRepository.get('relative');
                    this.$router.push(link);
                    return;
                }
                this.page = jobs.pagination.page;
                this.result = this.result.concat(jobs.getRaw());


                this.isLoaded = true;
            });
        },
        onOpenSidebarClick()
        {
            // Inject our close handler
            const that = this;
            this.context.store.set(AppStoreKeys.SIDEBAR_COMPONENT_LEFT, {
                extends: JobListFilter,
                methods: {
                    onCloseClick()
                    {
                        that.context.store.set(AppStoreKeys.SIDEBAR_COMPONENT_LEFT, null);
                    }
                }
            });
        }
    },
    created()
    {
        if (this.isSerps) {
            this.context.store.set(AppStoreKeys.PAGE_HEADLINE_OVERRIDE, this.context.data.get('headline'));
        }
    },
    mounted()
    {
        // Inject the serp data if it is present
        if (this.isSerps) {
            const data: State = this.context.data;
            if (data.get('demand', null)) {
                JobService.initializeSerpsPage(data.get('demand'), data.get('searchLabel'));
            }
        }

        this.onMounted();

        // Reset the result list of the demand has changed
        this.unbinder = JobService.watchDemand((k, n, o) => {
            this.result = [];
            this.onSearchChange();
        });
    },
    beforeDestroy()
    {
        JobService.leaveSerpsPage(false);
        this.unbinder();
    }
};
