var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "radius no-label jobFilterSection jobFilterSection--radius",
    },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("jobFilter.form.radius.label")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("select-box", {
        attrs: { id: _vm.id, items: _vm.options },
        on: { input: _vm.emitDemandFilterEvent },
        model: {
          value: _vm.demand.radius,
          callback: function ($$v) {
            _vm.$set(_vm.demand, "radius", $$v)
          },
          expression: "demand.radius",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }