var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jobSearch" },
    [
      _vm.hasSerpText
        ? _c("CmsTextLinkBlock", { staticClass: "jobSearch__serpText" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.serpText) } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "jobSearch__scrollAnchor",
        attrs: { id: "jobContainer" },
      }),
      _vm._v(" "),
      _c("green-container", [
        _c("div", { staticClass: "jobSearch__grid" }, [
          _c("div", { staticClass: "jobSearch__meta" }, [
            _c(
              "div",
              [
                _c("job-chip-filter", { staticClass: "tags" }),
                _vm._v(" "),
                _vm.hasFilterInSidebar
                  ? _c("div", { staticClass: "filter-button" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.onOpenSidebarClick()
                            },
                          },
                        },
                        [_c("icon", { attrs: { icon: "filter" } })],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("job-sorting-selection", {
                  attrs: { id: "job-search-sorting" },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "jobSearch__form" },
            [!_vm.hasFilterInSidebar ? _c("job-list-filter") : _vm._e()],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "jobSearch__list" },
            [
              _c("job-item-list", {
                attrs: { "is-loaded": _vm.isLoaded, jobs: _vm.jobs },
              }),
              _vm._v(" "),
              _c("infinity-loading-spinner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLoaded,
                    expression: "!isLoaded",
                  },
                ],
              }),
              _vm._v(" "),
              _c(
                "empty-list-notification",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.jobs,
                      expression: "!jobs",
                    },
                  ],
                  attrs: { "has-green-background": true },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("jobSearch.list.noResults")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isLoaded
                ? _c("nav", { staticClass: "jobSearch__pagination" }, [
                    _c(
                      "ul",
                      [
                        _c(
                          "li",
                          { staticClass: "jobSearch__paginationArrow" },
                          [
                            _vm.page !== 1
                              ? _c(
                                  "link-wrap",
                                  {
                                    attrs: {
                                      link: _vm.paginationLink(_vm.page - 1),
                                      disabled: "",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onPaginationClick(
                                          _vm.page - 1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                ❮\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.pages, function (pageNumber) {
                          return _c(
                            "li",
                            {
                              key: "page-" + pageNumber,
                              class: _vm.paginationClasses(pageNumber),
                            },
                            [
                              pageNumber !== _vm.page
                                ? _c(
                                    "link-wrap",
                                    {
                                      attrs: {
                                        link: _vm.paginationLink(pageNumber),
                                        disabled: "",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.onPaginationClick(
                                            pageNumber
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(pageNumber) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(pageNumber) +
                                        "\n                            "
                                    ),
                                  ]),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "jobSearch__paginationArrow" },
                          [
                            _vm.page !== _vm.pages
                              ? _c(
                                  "link-wrap",
                                  {
                                    attrs: {
                                      link: _vm.paginationLink(_vm.page + 1),
                                      disabled: "",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onPaginationClick(
                                          _vm.page + 1
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                ❯\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }